<template>
  <div>
    <div class="row">
      <div class="col mt-5">
        <div class="input-group form-control">
          <label class="fields mr-2">Img URLs</label><span> {{ getJsonDataField('api_input.prompt_messages.img_urls', 0) || 'No' }}</span>
        </div>
        <div class="input-group form-control">
          <label class="fields mr-2">Input Links</label> <span>{{ getJsonDataField('api_input.prompt_messages.img_urls', 0) || 'No' }}</span>
        </div>
        <div class="input-group form-control">
          <label class="fields mr-2">Enable web search</label><span> {{ getJsonDataField('api_input.prompt_messages.img_urls', 0) || 'No' }}</span>
        </div>
        <div class="input-group form-control">
          <label class="fields mr-2">Order number</label><span> {{ this.currentOrderDetails?.id }}</span>
        </div>
      </div>
      <div class="col mt-5">
        <div class="input-group form-control">
          <label class="fields mr-2">Input URL</label><span> {{ this.currentOrderDetails?.html_content_image_url }}</span>
        </div>
        <div class="input-group form-control">
          <label class="fields mr-2">Temperature</label><span> {{ getJsonDataField('api_input.params.temperature', 0) || 'No' }}</span>
        </div>
        <div class="input-group form-control">
          <label class="fields mr-2">Query search</label><span> {{ getJsonDataField('api_input.query_search', 0) || 'No' }}</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label class="fields">Sistem</label>
        <div class="input-group form-control h-auto">
          <span class="role-content"> {{ getSystemRoleContent()  || 'No' }}</span>
        </div>
      </div>
      <div class="col">
        <label class="fields">User</label>
        <div class="input-group form-control h-auto">
          <span class="role-content" v-html="getUserRoleContent() || 'no'"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { BaseInput } from "@/components";
import { BaseTextarea } from "src/components/index";

export default {
  name: "ContentsToolPrompt",
  components: {
    BaseInput,
    BaseTextarea,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters("contentsTool", ["getGroupedOrderDetails"]),
    currentOrderDetails() {
      const groupedOrderDetails = this.getGroupedOrderDetails || {};
      const order = groupedOrderDetails[this.orderId];

      if (order && Array.isArray(order.order_details)) {
        order.order_details.forEach(item => {
          if (item.json_data && typeof item.json_data === 'string') {
            try {
              item.jsonData = JSON.parse(item.json_data);
            } catch (e) {
              console.error("Errore nel parsing JSON:", e);
              item.jsonData = {};
            }
          } else {
            item.jsonData = item.json_data;
          }
        });
      }

      return order || {};
    },
  },
  methods: {
    getJsonDataField(fieldPath, index = 0) {
      const order = this.currentOrderDetails;
      if (order && order.order_details && order.order_details[index] && order.order_details[index].jsonData) {
        const data = order.order_details[index].jsonData;
        return fieldPath.split('.').reduce((obj, key) => obj && obj[key], data);
      }
      return null;
    },
    getSystemRoleContent() {
      const order = this.currentOrderDetails;
      let systemContent = '';

      if (order && order.order_details) {
        for (let item of order.order_details) {
          if (item.jsonData && item.jsonData.api_input && Array.isArray(item.jsonData.api_input.prompt_messages)) {
            const systemMessage = item.jsonData.api_input.prompt_messages.find(msg => msg.role === 'system');
            if (systemMessage) {
              systemContent = systemMessage.content;
              break;
            }
          }
        }
      }

      return systemContent || '';
    },
    getUserRoleContent() {
      const order = this.currentOrderDetails;
      let concatenatedContent = '';

      if (order && order.order_details) {
        for (let item of order.order_details) {
          if (item.jsonData && item.jsonData.api_input && Array.isArray(item.jsonData.api_input.prompt_messages)) {
            const userMessages = item.jsonData.api_input.prompt_messages.filter(msg => msg.role === 'user');
            userMessages.forEach((msg, index) => {
              if (index > 0) {
                concatenatedContent += '<br><br>';
              }
              concatenatedContent += msg.content;
            });
          }
        }
      }

      return concatenatedContent || '';
    }
  }
};
</script>

<style scoped>
.fields {
  font-size: 14px !important;
}
.role-content {
  max-height: 115px;
  overflow-y: auto;
}
</style>
