<template>
  <div class="copy_social__grey_container mb-4">
    <div class="d-flex align-items-center justify-content-between">
      <label class="d-flex">
        {{ title }}
        <el-tooltip v-if="tooltipText.length" :content="tooltipText" placement="top">
          <span class="ml-2 tooltip-info">i</span>
        </el-tooltip>
      </label>
      <el-switch v-model="switchInput" class="keywords-table-switch"></el-switch>
    </div>
    <div v-if="switchInput">
      <slot></slot>
    </div>
  </div>
</template>
<script>

import { Switch } from 'element-ui';
export default {
  name: "PopupInput",
  components: {
    [Switch.name]: Switch,
  },
  props: {
    title: {
      type: String
    },
    tooltipText: {
      type: String,
      default: ''
    },
    switchmetadata: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    switchInput(value) {
      this.$emit('change-value', value);
    },
  },
  data() {
    return {
      switchInput: false
    };
  },
  computed: {
  },
  methods: {
  },
  mounted() {
    if(this.switchmetadata ){
      this.switchInput = this.switchmetadata
    }
  }
};

</script>

<style scoped></style>
