var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 px-3",class:{
        'col-lg-9': _vm.formOpened,
        'col-lg-12': !_vm.formOpened,
      }},[_c('card',{staticClass:"border-card mt-3"},[(!_vm.formOpened)?_c('div',{staticClass:"d-flex align-self-sm-baseline justify-content-end ml-3 mb-3",staticStyle:{"gap":"-30px"}},[_c('base-button',{staticClass:"btn__tooltip--folder-document",attrs:{"disabled":_vm.formOpened},on:{"click":_vm.openForm}},[_c('i',{staticClass:"fa-light fa-sidebar-flip mr-2"}),_vm._v(" "+_vm._s(_vm.$t("ContentsTool.buttonForm"))+" ")])],1):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-4"},[_c('a',{attrs:{"href":"#"},on:{"click":_vm.goBack}},[_c('i',{staticClass:"fa-solid fa-arrow-left"}),_vm._v(" Go Back ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"custom_titles_color mb-2"},[_vm._v(_vm._s(_vm.orderName))]),_c('label',{staticClass:"custom_subtitle_color"},[_vm._v(_vm._s(_vm.formatDate(_vm.createdAt)))])]),_c('div',{staticClass:"col-9"},[_c('div',{staticClass:"button_switch_panel-container"},[_c('button',{staticClass:"button_switch_panel",class:_vm.switchTab == 'orderDetails' ? 'button_switch_panel--selected' : '',on:{"click":function($event){_vm.switchTab = 'orderDetails'}}},[_vm._v(" Order details ")]),_c('button',{staticClass:"button_switch_panel",class:_vm.switchTab == 'promptData'
                    ? 'button_switch_panel--selected'
                    : '',on:{"click":function($event){_vm.switchTab = 'promptData'}}},[_vm._v(" Prompt data ")]),_c('button',{staticClass:"button_switch_panel",class:_vm.switchTab == 'upload'
                    ? 'button_switch_panel--selected'
                    : '',on:{"click":function($event){_vm.switchTab = 'upload'}}},[_vm._v(" Upload ")])])]),_c('div',{staticClass:"col-3 mt-3"},[_c('el-select',{staticClass:"select-primary m-0",attrs:{"id":"view","placeholder":"View all","filterable":""},model:{value:(_vm.view),callback:function ($$v) {_vm.view=$$v},expression:"view"}},[_c('el-option',{staticClass:"select-primary",attrs:{"value":"view-all","label":"View All"}}),_c('el-option',{staticClass:"select-primary",attrs:{"value":"processed","label":"Processed"}}),_c('el-option',{staticClass:"select-primary",attrs:{"value":"in-process","label":"In Process"}}),_c('el-option',{staticClass:"select-primary",attrs:{"value":"waiting_queue","label":"Waiting Queue"}}),_c('el-option',{staticClass:"select-primary",attrs:{"value":"rejected","label":"Rejected"}})],1)],1),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('transition',{attrs:{"name":"tab-fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.switchTab == 'orderDetails'),expression:"switchTab == 'orderDetails'"}],staticClass:"col-12"},[_c('ContentsToolOrderTable',{attrs:{"orderId":_vm.selectedOrderId,"filterStatus":_vm.view}})],1)])],1)]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('transition',{attrs:{"name":"tab-fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.switchTab == 'promptData'),expression:"switchTab == 'promptData'"}],staticClass:"col-12 border-card"},[_c('ContentsToolPrompt',{attrs:{"orderId":_vm.selectedOrderId}})],1)])],1)])])])],1),_c('div',{staticClass:"col-md-12 px-3 mt-3",class:{
        'col-lg-3': _vm.formOpened,
        'd-none': !_vm.formOpened,
      }},[(_vm.formOpened)?_c('ContentsToolOrder',{attrs:{"orders":_vm.orders},on:{"closeForm":_vm.closeForm}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }