import Vue from "vue";
import Vuex from "vuex";
import moduleUser from "./modules/user";
import moduleUserSeats from "./modules/userSeats";
import moduleNotificationGroups from "./modules/notificationGroups";
import moduleWorkspace from "./modules/workspace";
import moduleBrandVoices from "./modules/brandVoices";
import moduleOrders from "./modules/orders";
import moduleTaskAssignment from "./modules/taskAssignment";
import moduleContentsTool from "./modules/contentsTool"
import moduleWebsites from "./modules/websites";
import moduleLinkBuilder from "./modules/linkBuilder";
import modulePageUrl from "./modules/pageUrl";
import moduleLinkBuilderGeneric from "./modules/linkBuilderGeneric";
import toolsData from "@/jsonFiles/tools.json";
import axios from "@/axios";
import router from "@/routes/router";
import { apiHandler } from "@/util/errorHandling";
import { Message } from "element-ui";
import moduleFolder from "./modules/folders";
import moduleBriefings from "./modules/briefings";
import moduleWorkspaceLinkSources from "./modules/workspaceLinkSources";
import moduleTaskAssignmentGeneric from "./modules/taskAssignmentGeneric";
import moduleCategoryCardsKeywords from "./modules/categoryCardsKeywords";

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    count: 0,
    isLoading: false,
    step: 1,
    tovs: [],
    languages: [],
    tools: [],
    toolsCategories: [], // only AI Writer
    countries: [],
  };
};

const store = new Vuex.Store({
  modules: {
    user: moduleUser,
    orders: moduleOrders,
    taskAssignment: moduleTaskAssignment,
    userSeats: moduleUserSeats,
    notificationGroups: moduleNotificationGroups,
    workspace: moduleWorkspace,
    brandVoices: moduleBrandVoices,
    folders: moduleFolder,
    websites: moduleWebsites,
    linkBuilder: moduleLinkBuilder,
    linkBuilderGeneric: moduleLinkBuilderGeneric,
    moduleCategoryCardsKeywords: moduleCategoryCardsKeywords,
    pageUrls: modulePageUrl,
    briefings: moduleBriefings,
    workspaceLinkSources: moduleWorkspaceLinkSources,
    taskAssignmentGeneric: moduleTaskAssignmentGeneric,
    contentsTool: moduleContentsTool,
  },
  state: getDefaultState(),
  getters: {
    getIsLoading(state) {
      return state.isLoading;
    },
    getStep(state) {
      return state.step;
    },
    getTools(state) {
      return state.tools;
    },
    getToolsCategories(state) {
      return state.toolsCategories;
    },
    getTovs(state) {
      return state.tovs;
    },
    getLanguages(state) {
      return state.languages;
    },
    getCountries(state) {
      return state.countries;
    },
  },
  mutations: {
    async resetState(state) {
      console.log("resetState");
      Object.assign(state, getDefaultState());
    },
    increment(state) {
      state.count++;
    },
    setStep(state, step) {
      state.step = step;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsFavorite(state, { toolId, isFavorite }) {
      state.tools.find((el) => el.id === toolId).is_favorite = isFavorite;
    },
    setTools(state, tools) {
      // Filters down only the tools in the tools.json file
      state.tools = tools
        .filter((tool) => toolsData.tools.some((t) => t.slug === tool.slug))
        .map((tool) => {
          const toolExtraInfo = toolsData.tools.find(
            (t) => t.slug === tool.slug,
          );
          //override macrocategory property only for premium tools
          if (tool.is_custom) {
            toolExtraInfo.macrocategory = "custom_tools";
            if (
              state.user.user.extra_info &&
              state.user.user.extra_info.custom_tool_name
            ) {
              toolExtraInfo.name = `Custom ${state.user.user.extra_info.custom_tool_name} ${tool.name}`;
            }
          }
          // Override tool properties with the ones from toolExtraInfo (if existing)
          return { ...tool, ...toolExtraInfo };
        });
    },
    setToolsCategories(state, categories) {
      state.toolsCategories = categories;
    },
    setTovs(state, tovs) {
      state.tovs = tovs;
    },
    setLanguages(state, languages) {
      state.languages = languages;
    },
    setCountries(state, countries) {
      state.countries = countries;
    },
  },
  actions: {
    async fetchTovs({ getters, commit }) {
      const user = getters.getUser;
      const params = {
        user_id: user.id,
      };

      const response = await axios.get("tools/show_user_tovs", {
        params: params,
      });

      const tovs = [];
      for (let i = 0; i < response.data.result[0].length; i++) {
        tovs.push({
          name: response.data.result[0][i].name,
          value: response.data.result[0][i].id,
          brand_voice: true,
          selected: response.data.default && i == 0 ? true : false,
        });
      }
      for (let j = response.data.result[1].length - 1; j >= 0; j--) {
        tovs.push({
          name: response.data.result[1][j].key,
          value: response.data.result[1][j].value,
          brand_voice: false,
          selected: false,
        });
      }

      // Update Vuex store with the retrieved data
      commit("setTovs", tovs);
    },

    async fetchLanguages({ getters, commit }) {
      const user = getters.getUser;
      const params = {
        user_id: user.id,
      };

      const response = await axios.get("tools/show_language_codes", {
        params: params,
      });

      // Update Vuex store with the retrieved data
      const languages = response.data.result;
      commit("setLanguages", languages);
    },

    async fetchCountries({ getters, commit }) {
      const user = getters.getUser;
      const params = {
        user_id: user.id,
      };

      const response = await axios.get(
        "services/orders/tools/trends/get_params",
        {
          params: params,
        },
      );

      // Update Vuex store with the retrieved data
      console.log(response.data.data);
      const countries = response.data.data;
      commit("setCountries", countries);
    },
    async toggleFavorite(context, { isFavorite, user_id, template_id }) {
      let action = isFavorite ? "delete" : "create";

      const response = await axios.post("tools/update_user_favorite", {
        user_id: user_id,
        action: action,
        template_id: template_id,
      });
      if (response?.data?.status !== "success") {
        Message({
          showClose: true,
          message: response?.data?.message,
          type: "error",
        });
      } else {
        Message({
          showClose: true,
          message: response?.data?.message,
          type: "success",
        });
      }
      context.commit("setIsFavorite", {
        toolId: template_id,
        isFavorite: response.data.is_favorite,
      });
    },
  },
});

export default store;
