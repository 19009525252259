<template>
  <div>
    <div class="row">
      <div
        class="col-md-12 px-3"
        :class="{
          'col-lg-9': formOpened,
          'col-lg-12': !formOpened,
        }"
      >
        <card class="border-card mt-3">
          <div class="d-flex align-self-sm-baseline justify-content-end ml-3 mb-3"
              v-if="!formOpened"
              style="gap: -30px"
            >
            <base-button @click="openForm" :disabled="formOpened" class="btn__tooltip--folder-document">
              <i class="fa-light fa-sidebar-flip mr-2"></i>
              {{ $t("ContentsTool.buttonForm") }}
            </base-button>
          </div>

          <div class="row">
            <div class="col-12 mb-4">
              <a href="#" @click="goBack" class="">
                <i class="fa-solid fa-arrow-left"></i>  Go Back
              </a>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <h1 class="custom_titles_color mb-2">{{ orderName }}</h1>
              <label class="custom_subtitle_color">{{ formatDate(createdAt) }}</label>
            </div>
            <div class="col-9">
              <div class="button_switch_panel-container">
                <button
                  @click="switchTab = 'orderDetails'"
                  class="button_switch_panel"
                  :class="
                    switchTab == 'orderDetails' ? 'button_switch_panel--selected' : ''
                  "
                >
                  Order details
                </button>
                <button
                  @click="switchTab = 'promptData'"
                  class="button_switch_panel"
                  :class="
                    switchTab == 'promptData'
                      ? 'button_switch_panel--selected'
                      : ''
                  "
                >
                  Prompt data
                </button>
                <button
                  @click="switchTab = 'upload'"
                  class="button_switch_panel"
                  :class="
                    switchTab == 'upload'
                      ? 'button_switch_panel--selected'
                      : ''
                  "
                >
                  Upload
                </button>
              </div>
            </div>
            <div class="col-3 mt-3">
              <el-select
                id="view"
                placeholder="View all"
                filterable
                class="select-primary m-0"
                v-model="view"
              >
                <el-option class="select-primary" value="view-all" label="View All"></el-option>
                <el-option class="select-primary" value="processed" label="Processed"></el-option>
                <el-option class="select-primary" value="in-process" label="In Process"></el-option>
                <el-option class="select-primary" value="waiting_queue" label="Waiting Queue"></el-option>
                <el-option class="select-primary" value="rejected" label="Rejected"></el-option>
              </el-select>
            </div>

            <div class="col-12">
              <div class="row">
                <transition name="tab-fade">
                  <div v-show="switchTab == 'orderDetails'" class="col-12">
                    <ContentsToolOrderTable :orderId="selectedOrderId" :filterStatus="view" />
                  </div>
                </transition>
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <transition name="tab-fade">
                  <div v-show="switchTab == 'promptData'" class="col-12 border-card">
                    <ContentsToolPrompt :orderId="selectedOrderId" />
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </card>
      </div>

      <div
        class="col-md-12 px-3 mt-3"
        :class="{
          'col-lg-3': formOpened,
          'd-none': !formOpened,
        }"
      >
        <ContentsToolOrder v-if="formOpened" @closeForm="closeForm" :orders="orders"/>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import { formatDate } from "@/util/date";
import ContentsToolOrderTable from "./ContentsToolOrderTable.vue";
import ContentsToolPrompt from "./ContentsToolPrompt.vue";
import ContentsToolOrder from "./ContentsToolOrder.vue";
import { Select, Option } from "element-ui";

export default {
  name: "ContentsToolOrderDetails",
  components: {
    ContentsToolOrderTable,
    ContentsToolPrompt,
    ContentsToolOrder,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      formOpened: true,
      switchTab: "orderDetails",
      orderName: "",
      createdAt: "",
      selectedOrderId: this.$route.params.id,
      view: "view-all",
    };
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(newId) {
        this.selectedOrderId = newId;
        this.getOrderDetails();
      },
    },
  },
  computed: {
    ...mapGetters("contentsTool", ["getGroupedOrderDetails"]),
    orders() {
      return this.$store.getters.getOrders;
    },
  },
  methods: {
    ...mapActions("contentsTool", ["fetchContentsToolOrders", "startPolling", "stopPolling"]),
    formatDate,
    openForm() {
      this.formOpened = true;
    },
    closeForm() {
      this.formOpened = false;
    },
    getOrderDetails() {
      const groupedOrderDetails = this.getGroupedOrderDetails || {};
      const order = groupedOrderDetails[this.selectedOrderId];
      if (order) {
        this.orderId = order.id;
        this.orderName = order.name;
        this.createdAt = order.created_at;
      }
    },

    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.fetchContentsToolOrders();
    this.startPolling();
    this.getOrderDetails();
  },
  beforeDestroy() {
    this.stopPolling();
  },
  beforeMount() {
    const availableTabs = ["orderDetails"];
    const selectedTab = this.$route.params?.tab;

    if (selectedTab && availableTabs.includes(selectedTab)) {
      this.switchTab = selectedTab;
    }
  },
};
</script>

<style scoped>
.btn {
  margin-top: 20px;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #0056b3;
}
.white-content a {
  color: #999999 !important;
}
.custom_subtitle_color {
  color: #566A7F !important;
}
</style>
