import axios from "@/axios";

const getDefaultState = () => {
  return {
    form: {},
    responseData: null,
    setResponse: [],
    groupedOrderDetails: null,
  };
};

const moduleContentsTool = {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getForm(state) {
      return state.form;
    },
    getResponseData(state) {
      return state.responseData;
    },
    getContentsToolOrder(state) {
      return state.setResponse;
    },
    getGroupedOrderDetails(state) {
      return state.groupedOrderDetails;
    },
    selectedWorkspace: state => state.selectedWorkspace,
  },
  mutations: {
    setForm(state, form) {
      state.form = { ...form };
    },
    setResponse(state, responseData) {
      state.responseData = responseData;
    },
    setGroupedOrderDetails(state, groupedOrderDetails) {
      state.groupedOrderDetails = groupedOrderDetails;
    },
    setPollingInterval(state, interval) {
      state.pollingInterval = interval;
    },
    clearPollingInterval(state) {
      if (state.pollingInterval) {
        clearInterval(state.pollingInterval);
        state.pollingInterval = null;
      }
    },
  },
  actions: {
    async fetchContentsToolOrders({ commit, rootGetters }) {
      try {
        const response = await axios.get('/tools/contents/orders');
        const rawData = response.data.data;
        const parsedData = JSON.parse(rawData)[""];

        const groupedOrderDetails = parsedData.reduce((acc, order) => {
          if (order.order_details && order.order_details.length > 0) {
            const firstOrderDetail = order.order_details[0];
            acc[order.id] = {
              name: firstOrderDetail.name,
              updated_at: order.updated_at,
              status: firstOrderDetail.status,
              order_details: order.order_details,
              workspace_id: order.workspace_id
            };
          }
          return acc;
        }, {});

        commit('setGroupedOrderDetails', groupedOrderDetails);
      } catch (error) {
        console.error('Errore durante il fetch:', error);
        throw error;
      }
    },
    async fetchOrderStatus({ commit, dispatch }) {
      try {
        const response = await axios.get('/tools/contents/orders');
        const rawData = response.data.data;

        if (!rawData || !rawData.length) {
          commit('setGroupedOrderDetails', {});
          dispatch('stopPolling');
          return;
        }

        const parsedData = JSON.parse(rawData)[""];
        if (!parsedData || parsedData.length === 0) {
          commit('setGroupedOrderDetails', {});
          dispatch('stopPolling');
          return;
        }

        const updatedOrderDetails = parsedData.reduce((acc, order) => {
          if (order.order_details && order.order_details.length > 0) {
            const firstOrderDetail = order.order_details[0];
            acc[order.id] = {
              ...order,
              name: firstOrderDetail.name,
              description: firstOrderDetail.description,
              updated_at: order.updated_at,
              status: firstOrderDetail.status,
            };
          }

          if (!parsedData || parsedData.length === 0) {
            commit('setGroupedOrderDetails', {});
            dispatch('stopPolling');
            return;
          }

          return acc;
        }, {});

        commit('setGroupedOrderDetails', updatedOrderDetails);

        const allProcessed = Object.values(updatedOrderDetails).every(order =>
          order.order_details.every(detail => detail.status === "accepted")
        );

        if (allProcessed) {
          dispatch('stopPolling');
        }

      } catch (error) {
        console.error('Errore durante il fetch dello status:', error);
        commit('setGroupedOrderDetails', {});
        dispatch('stopPolling');
        throw error;
      }
    },
    async createRequest({ commit, rootGetters, dispatch }, form, globalData) {
      try {
        const response = await axios.post(`/tools/contents/create-request`, {
          globalData: globalData,
          data: form,
          workspace_id: rootGetters.selectedWorkspace?.id
        });

        commit('setResponse', response.data);

        dispatch('startPolling');

        return response;
      } catch (error) {
        console.error('Errore nella richiesta:', error);
        throw error;
      }
    },
    startPolling({ commit, dispatch, state }) {
      if (!state.pollingInterval) {
        const interval = setInterval(() => {
          dispatch('fetchOrderStatus');
        }, 1000);
        commit('setPollingInterval', interval);
      }
    },
    stopPolling({ commit }) {
      commit('clearPollingInterval');
    },
  },

};

export default moduleContentsTool;
