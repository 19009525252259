<template>
  <card class="border-card">
    <div class="d-flex align-items-center justify-content-between">
      <h4 class="title m-0">{{ $t("ContentsTool.form.title") }}</h4>
      <base-button @click="closeForm" icon link style="color: #d6d6d6">
        <i class="fa-solid fa-circle-xmark"></i>
      </base-button>
    </div>

    <div class="row">
      <div class="col-12 mb-3">
        <input
          type="text"
          v-model="searchQuery"
          class="form-control"
          placeholder="Search..."
        />
      </div>
    </div>

    <div v-if="filteredOrderDetails.length > 0">
      <card
        class="border-card"
        :class="{ 'selected-card': order.id === Number($route.params.id) }"
        v-for="(order, orderId) in filteredOrderDetails"
        :key="orderId"
      >
        <router-link :to="`/contents-tool-order/${order.id}`"  class="mb-2">{{ order.name }}
        </router-link>
        <el-dropdown trigger="click" class="float-right">
          <base-button type="secondary" icon size="sm" class="btn-simple">
            <i class="fa-solid fa-ellipsis-vertical"></i>
          </base-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item href="#"  @click.native.prevent="downloadZipOrder(order, true)">
              <i class="fa-sharp fa-thin fa-file-zipper"></i> Download zip
            </el-dropdown-item>
            <el-dropdown-item href="#"  @click.native.prevent="downloadCsvOrder(order, true)">
              <i class="fa-regular fa-file-csv"></i> Export CSV
            </el-dropdown-item>
            <el-dropdown-item :disabled="true">
              <i class="fa-sharp-duotone fa-solid fa-clone"></i> Copy prompt
            </el-dropdown-item>
            <el-dropdown-item :disabled="true">
              <i class="fa-solid fa-plus"></i> New job from this
            </el-dropdown-item>
            <el-dropdown-item :disabled="true">
              <i class="fa-regular fa-pen-to-square"></i> Rename order
            </el-dropdown-item>
            <el-dropdown-item href="#"  @click.native.prevent="deleteOrder(order, true)">
              <i class="fa-regular fa-trash"></i> Trash
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <br>

        <label class="order-date mt-2">{{ formatDate(order.updated_at) }} - Client:</label> {{ order.description }}
        <div class="progress-container">
          <div
            class="progress-bar"
            :class="{ 'progress-complete': getOrderProgress(order) === 0 }"
            :style="{ width: getOrderProgress(order) + '%' }"
          ></div>
        </div>
      </card>
    </div>
    <div v-else>
      <p>{{ 'No orders available' }}</p>
    </div>

    <div
      slot="footer"
      class="col-12 justify-content-center justify-content-sm-between flex-wrap"
    >
      <div>
        <!-- <p class="card-category">
          {{ $t('pagination.showing') }} {{ from }} {{ $t('pagination.to') }}
          {{ $t('pagination.of') }} {{ total }} {{ $t('pagination.entries') }}
        </p> -->
      </div>
      <base-pagination
        class="pagination-no-border"
        v-model="currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { formatDate } from "@/util/date";
import {BasePagination } from "src/components";
import {Dropdown, DropdownItem, DropdownMenu, Dialog } from "element-ui";

export default {
  name: "ContentsToolOrder",
  components: {
    BasePagination,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      searchQuery: "",
      currentPage: 1,
      pagination: {
        perPage: 8,
      },
      total: 0,
    };
  },
  computed: {
    ...mapGetters("contentsTool", ["getGroupedOrderDetails"]),
    filteredOrderDetails() {
      const groupedOrderDetails = this.getGroupedOrderDetails || {};
      const selectedWorkspaceId = this.$store.getters.selectedWorkspace.id;

      let orders = Object.values(groupedOrderDetails).filter(
        order => order.workspace_id === selectedWorkspaceId
      );

      if (this.searchQuery) {
        orders = orders.filter(order =>
          order.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          order.description.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }

      orders = orders.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

      this.total = orders.length;

      const start = (this.currentPage - 1) * this.pagination.perPage;
      const end = start + this.pagination.perPage;
      return orders.slice(start, end);
    },
    from() {
      return Math.min((this.currentPage - 1) * this.pagination.perPage + 1, this.total);
    },
    to() {
      return Math.min(this.currentPage * this.pagination.perPage, this.total);
    },
  },
  methods: {
    ...mapActions("contentsTool", ["fetchContentsToolOrders", "startPolling", "stopPolling"]),
    formatDate,
    closeForm() {
      this.$emit("closeForm");
    },
    getOrderProgress(order) {
      if (!order.order_details || order.order_details.length === 0) {
        return 100;
      }

      const totalOrderDetails = order.order_details.length;
      const nonAcceptedOrderDetails = order.order_details.filter(
        detail => detail.status !== "accepted"
      ).length;

      return Math.round(((totalOrderDetails - nonAcceptedOrderDetails) / totalOrderDetails) * 100);
    },
    goToPage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    async downloadZipOrder(order) {
      if (order.order_details && order.order_details.length > 0) {
        const firstOrderDetail = order.order_details[0];
        const orderDetailId = firstOrderDetail.id;
        this.isLoading = true;
        await this.$store.dispatch("downloadOrder", {
          orderDetailId: orderDetailId,
          format: "zip",
          type: "batch",
        });
        this.isLoading = false;
      } else {
        console.error("No order details found for this order.");
      }
    },
    async downloadCsvOrder(order) {
      try {
        if (!order || !order.order_details || order.order_details.length === 0) {
          console.error("No order details available.");
          return;
        }

        const stripHtmlTags = (html) => {
          const tempDiv = document.createElement("div");
          tempDiv.innerHTML = html;
          return tempDiv.innerText.trim();
        };

        const header = "ID,Name,Content\n";
        const rows = order.order_details
          .map(detail => {
            const cleanHtmlContent = stripHtmlTags(detail.html_content);
            return `${detail.id},"${detail.name.replace(/"/g, '""')}","${cleanHtmlContent.replace(/"/g, '""')}"`;
          })
          .join("\n");
        const csvContent = header + rows;

        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `Order_${order.id}.csv`;
        link.click();

      } catch (error) {
        console.error("Error exporting CSV:", error);
      }
    },
    async deleteOrder(order) {
      if (order.order_details && order.order_details.length > 0) {
        const firstOrderDetail = order.order_details[0];
        const orderId = firstOrderDetail.order_id;
        await this.$store.dispatch("deleteOrder", { order_id: orderId });
        this.fetchContentsToolOrders();
      } else {
        console.error("No order details found for this order.");
      }
    }

  },
  mounted() {
    this.fetchContentsToolOrders();
    this.startPolling();
  },
  beforeDestroy() {
    this.stopPolling();
  },
};
</script>

<style scoped>
.order-title {
  font-size: 16px !important;
}
.order-date {
  font-size: 14px !important;
  color: #566A7F !important;
}

input.form-control {
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
}

.progress-container {
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin: 10px 0;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  border-radius: 5px;
  background-color: #007BFF;
  transition: width 1s ease-in-out;
  width: 0%;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination button:disabled {
  background-color: #d6d6d6;
  cursor: not-allowed;
}

.pagination span {
  margin: 0 10px;
  font-size: 14px;
}

.white-content a {
  color: #004583 !important;
}
.selected-card {
  background-color: #F2F4F6 !important;
  border-color: #007bff;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}

.selected-card .order-title {
  font-weight: bold;
  color: #007bff;
}
.btn-simple {
  border: none !important;
  color: #999999 !important;
}
.white-content .btn-simple {
  background-color: transparent !important;
  color: #999999 !important;
}
.btn-simple:hover {
  border: none !important;
  color: #999999 !important;
}
.white-content .btn-simple:hover {
  background-color: transparent !important;
  color: #999999 !important;
}
.white-content .el-dropdown-menu__item:hover, .white-content .el-dropdown-menu__item:focus {
    color: #fff !important;
}
.el-dropdown-menu__item a:hover, .el-dropdown-menu__item a:focus {
  color: white !important;
}
</style>
