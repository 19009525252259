<template>
  <div>
  <el-table :data="paginatedOrderDetails" class="order-table">
    <!-- Title -->
    <el-table-column min-width="200" label="Title" sortable>
      <template #default="scope">
        <label>
          <router-link target="_blank" :to="`/orders/${scope.row.id}`" class="mb-2">{{ scope.row.name }}
          </router-link><br>
        </label>
      </template>
    </el-table-column>

    <!-- Order Number -->
    <el-table-column min-width="200" label="Order number" sortable>
      <template #default="scope">
        {{ scope.row.id }}
      </template>
    </el-table-column>

    <!-- Created on -->
    <el-table-column min-width="200" label="Created on" sortable>
      <template #default="scope">
        {{ formatDate(scope.row.created_at) }}
      </template>
    </el-table-column>

    <!-- Status -->
    <el-table-column min-width="200" label="Status" sortable>
      <template #default="scope">
        <label class="badge w-50 text-center" :class="pillClassStatus(scope.row.status)"
          >{{ scope.row.status }}
        </label>
      </template>
    </el-table-column>

    <!-- Actions -->
    <el-table-column :min-width="135" align="center" label="Actions">
      <template #default="scope">
        <el-dropdown trigger="click">
          <base-button type="secondary" icon size="sm" class="btn-simple">
            <i class="fa-solid fa-ellipsis-vertical"></i>
          </base-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item href="#" @click.native.prevent="downloadZipFormat(scope.row.id)">
              <i class="fa-sharp fa-thin fa-file-zipper"></i> Download Zip
            </el-dropdown-item>
            <el-dropdown-item href="#" @click.native.prevent="download(scope.row.id)">
              <i class="fa-regular fa-file"></i> Download Doc
            </el-dropdown-item>
            <el-dropdown-item href="#" @click.native.prevent="deleteOrderDetails(scope.row.id)">
              <i class="fa-regular fa-trash"></i> Trash
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </el-table-column>
  </el-table>
  <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mt-4">
    <div>
        <p class="card-category">
          {{ $t('pagination.showing') }} {{ from }} {{ $t('pagination.to') }} {{ to }}
          {{ $t('pagination.of') }} {{ total }} {{ $t('pagination.entries') }}

        </p>
      </div>
      <base-pagination
        class="pagination-no-border"
        v-model="currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
</div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  Select,
  Option,
  DatePicker,
  Table,
  TableColumn,
  Collapse,
  CollapseItem,
} from "element-ui";
import { Dropdown, DropdownItem, DropdownMenu, Dialog } from "element-ui";
import { formatDate } from "@/util/date";
import { BasePagination } from "src/components";

export default {
  name: "ContentsToolOrderTable",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Dialog.name]: Dialog,
    BasePagination,
  },
  data() {
    return {
      currentPage: 1,
      pagination: {
        perPage: 10,
      },
    };
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
    filterStatus: {
      type: String,
      default: "view-all",
    },
  },
  computed: {
  ...mapGetters("contentsTool", ["getGroupedOrderDetails"]),
    filteredOrderDetails() {
      const groupedOrderDetails = this.getGroupedOrderDetails || {};
      const order = groupedOrderDetails[this.orderId];
      if (!order || !order.order_details) return [];

      if (this.filterStatus === "view-all") {
        return order.order_details;
      }
      return order.order_details.filter(
        (detail) => detail.status === this.filterStatus
      );
    },
    paginatedOrderDetails() {
      const start = (this.currentPage - 1) * this.pagination.perPage;
      const end = start + this.pagination.perPage;
      return this.filteredOrderDetails.slice(start, end);
    },
    total() {
      return this.filteredOrderDetails.length;
    },
    from() {
      return this.paginatedOrderDetails.length;
    },
    to() {
      return Math.min(this.currentPage * this.pagination.perPage, this.total);
    },
  },
  methods: {
    formatDate,
    pillClassStatus(status) {
      switch (status) {
        case "waiting_queue":
          return "pill--new";
        case "processing":
          return "pill--in_progress";
        case "accepted":
          return "pill--accepted";
        case "rejected":
          return "pill--failed";
        default:
          return "pill";
      }
    },
    async deleteOrderDetails(orderId) {
      try {
        this.isLoading = true;
        await this.$store.dispatch("deleteOrder", {
          order_detail_id: orderId,
        });

        const index = this.filteredOrderDetails.findIndex(
          (order) => order.id === orderId
        );
        if (index !== -1) {
          this.filteredOrderDetails.splice(index, 1);
        }
      } catch (error) {
        console.error("Errore durante l'eliminazione dell'ordine:", error);
      } finally {
        this.isLoading = false;
      }
    },
    downloadZipFormat(order) {
      this.$store.dispatch("downloadOrder", {
        orderDetailId: order,
        format: "zip",
        type: "batch",
      });
    },
    download(row, format = null) {
      this.$store.dispatch("downloadOrder", {
        orderDetailId: row,
        format,
      });
    },
  },
};
</script>

<style lang="scss">
.order-table {
  .badge {
      color: white;
      font-weight: 700;
      text-transform: uppercase;
      width: 58px;
      border-radius: 6px;
      margin: 0;
      padding: 0px 8px !important;

      &--new {
        width: auto;
        margin-right: 10px;
        font-weight: 700;
        font-size: 12px;
        text-transform: capitalize;
        padding: 0 7px;
        line-height: 15px;
        color: white;
        border: 1.5px solid white;
      }

      &.priority {
        &--high {
          background-color: rgba(245, 54, 92, 0.9);
        }

        &--medium {
          background-color: rgba(255, 157, 0, 0.9);
        }

        &--low {
          background-color: rgba(0, 123, 255, 0.9);
        }
      }
    }
}
</style>
<style lang="scss" scoped>
.task-table .link-table a {
  color: #007bff;
  text-decoration: none;
}
.task-table .link-table a:hover {
  text-decoration: underline;
}
.pill--failed {
  background-color: red !important;
}
.btn-simple {
  border: none !important;
  color: #999999 !important;
}
.white-content .btn-simple {
  background-color: transparent !important;
  color: #999999 !important;
}
.btn-simple:hover {
  border: none !important;
  color: #999999 !important;
}
.white-content .btn-simple:hover {
  background-color: transparent !important;
  color: #999999 !important;
}
.white-content .el-dropdown-menu__item:hover a:hover, .white-content .el-dropdown-menu__item:focus {
    color: #fff !important;
}
.el-dropdown-menu__item a:hover, .el-dropdown-menu__item a:focus {
  color: white !important;
}
</style>

